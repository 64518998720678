import { Row, Col } from "react-bootstrap";
import { Link } from "@reach/router";
import React from "react";

const IstormOfferGreyItem = ({partner, text, offer, instore, imgSrc, link}) => {
    return (
        <Row 
            className="justify-content-center align-items-center m-2 py-4"
            style={{backgroundColor: "#F1F3F5", borderRadius: "16px"}}
        >
            <Col xs="10" sm="10" className="text-center">
                <img
                src={imgSrc}
                alt="Phone"
                width="95%"
                style={{ maxWidth: "160px" }}
                />
            </Col>
            <Col className="mt-3 d-flex flex-column align-items-center">
                <h6 className="mb-2 font-weight-bold text-center text-sm-left">
                    {text}
                </h6>
                <h4
                className="text-center"
                style={{ color: "#3098cb" }}
                >
                <b>έως {offer}€ φθηνότερα.</b>
                </h4>
                <Row className="justify-content-center">
                <Col>
                    {instore ? (
                    <Link to={`${process.env.PUBLIC_URL}/${partner}`}>
                        <div className="istormNext next px-3 mt-4">
                        Νέα Αξιολόγηση
                        </div>
                    </Link>
                    ) : (
                    <Link
                        to={link}
                    >
                        <div
                        style={{ maxWidth: "300px" }}
                        className="istormNext next px-3 mt-4"
                        >
                        Βρες ένα κατάστημα iStorm
                        </div>
                    </Link>
                    )}
                </Col>
                </Row>
            </Col>
        </Row>   
    );
};

export default IstormOfferGreyItem;
